@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Tailwind CSS Scrollbar Styling */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent; /* Makes the track invisible */
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2); /* Slightly visible thumb */
  border-radius: 3px; /* Rounded edges */
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.4); /* Slightly brighter on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Makes scrollbar thinner */
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent; /* Thumb and track */
}

.grecaptcha-badge { visibility: hidden; }