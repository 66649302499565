@keyframes slideDown {
    0% {
        transform: translateX(-50%) translateY(-100%); /* Off-screen */
    }
    100% {
        transform: translateX(-50%) translateY(0); /* Fully visible */
    }
}

@keyframes slideUp {
    0% {
        transform: translateX(-50%) translateY(0); /* Fully visible */
    }
    100% {
        transform: translateX(-50%) translateY(-100%); /* Off-screen */
    }
}

.error-message {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    padding: 10px 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    opacity: 0; /* Invisible initially */
    pointer-events: none; /* Prevent interactions when invisible */
    transition: opacity 0.5s ease; /* Smooth fade effect */
}

.error-message.show {
    animation: slideDown 0.5s forwards; /* Slide in */
    opacity: 1; /* Visible */
    pointer-events: auto; /* Allow interactions */
}

.error-message.hide {
    animation: slideUp 0.5s forwards; /* Slide out */
    opacity: 0; /* Invisible */
    pointer-events: none; /* Prevent interactions */
}

.error-message p {
    margin: 0;
    font-size: 14px;
}
